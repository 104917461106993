import React from "react";
import "./NavBar.css";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import NavigationDrawer from "../NavigationDrawer";
// import PortalDrawer from "../PortalDrawer";

import PortalDrawer from "./PortalDrawer";
import SideDrawer from "./SideDrawer";
import NavigationDrawer from "./NavigationDrawer";

import Tooltip from "@mui/material/Tooltip";

const NavBar = () => {
  const navigate = useNavigate();
  const [isNavigationDrawerOpen, setNavigationDrawerOpen] = useState(false);

  const onHeadernavLink1Click = useCallback(() => {
    navigate("/mainservices");
  }, [navigate]);

  const onHeadernavLink2Click = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const onHeadernavLink3Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const openNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(true);
  }, []);

  const closeNavigationDrawer = useCallback(() => {
    setNavigationDrawerOpen(false);
  }, []);

  const onButtonmediumClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onButtonmedium1Click = useCallback(() => {
    //navigate("/welcomesignup");
    //navigate("/signup");
    navigate("/welcomepath");
  }, [navigate]);

  const onFrameButtonClick = useCallback(() => {
    //navigate("/signup");
    navigate("/welcomesignup");
  }, [navigate]);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  const onLinkToPageClick = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const onLinkToPage1Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const onLinkToPage2Click = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onLinkToPage3Click = useCallback(() => {
    navigate("/signup");
  }, [navigate]);

  const onButtonlargeClick = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onSeeMoreClick = useCallback(() => {
    navigate("/scholarships-category-page");
  }, [navigate]);

  const onButtonlarge6Click = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onButtonlarge8Click = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onButtonlarge10Click = useCallback(() => {
    navigate("/scholarships-description1");
  }, [navigate]);

  const onSeeMore1Click = useCallback(() => {
    navigate("/communities");
  }, [navigate]);

  const onButtonHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return (
    <>
      <div className="nav-bar">
        <div className="headertop-navsecondary">
          <div className="right-side-secondary-nav">
            <div className="headerdrop-downlang">
              <img className="language-icon" alt="" src="/language-icon.svg" />
              <div className="language">Language</div>
              <img className="down-icon" alt="" src="/down-icon.svg" />
            </div>
          </div>
          <div className="left-side-secondary-nav">
            <div className="headertop-navlink-parent">
              <div className="headertop-navlink">
                <div className="language">Students</div>
                <div className="line-under-link" />
              </div>
              <div className="headertop-navlink">
                <div className="language">Coaches</div>
                <div className="line-under-link1" />
              </div>
            </div>
          </div>
        </div>
        <div className="headertop-navprimary">
          <div className="left-side-top-nav-parent">
            <div className="left-side-top-nav">
              <div className="headerlogo-parent">
                <button className="headerlogo">
                  <img
                    className="headerlogo-icon15"
                    alt=""
                    src="/headerlogo5.svg"
                  />
                </button>
                <div className="top-nav-left">
                  <div className="headernav-link-parent">
                    <a className="headernav-link1" onClick={onButtonHomeClick}>
                      <div className="nav-active">Home</div>
                    </a>
                    <a
                      className="headernav-link1"
                      onClick={onHeadernavLink1Click}
                    >
                      <div className="nav-active">Services</div>
                    </a>
                    <a
                      className="headernav-link1"
                      onClick={onHeadernavLink2Click}
                    >
                      <div className="nav-active">Scholarships</div>
                    </a>
                    <a
                      className="headernav-link1"
                      //onClick={onHeadernavLink3Click}
                    >
                      <Tooltip title="Coming Soon" arrow>
                        <span>
                          <div className="headernav-link37">
                            {/* <div className="s-header-active"   onClick={onHeadernavLink3Click}>{`Community `}</div> */}
                            <div className="nav-active">{`Community `}</div>
                          </div>
                        </span>
                      </Tooltip>
                    </a>

                    <Tooltip title="Coming Soon" arrow>
                      <span>
                        <div className="headernav-link4">
                          <div className="nav-active">Resources</div>
                        </div>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-top-nav">
              <button className="menu" onClick={openNavigationDrawer}>
                <img className="vector-icon49" alt="" />
                <img className="vector-icon50" alt="" src="/vector19.svg" />
              </button>
              <div className="register">
                <button className="buttonmedium" onClick={onButtonmediumClick}>
                  <div className="button-text">Login</div>
                </button>
                <button
                  className="buttonmedium1"
                  onClick={onButtonmedium1Click}
                >
                  <div className="button-text1">Sign Up</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isNavigationDrawerOpen && (
        <SideDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeNavigationDrawer}
        >
          <NavigationDrawer onClose={closeNavigationDrawer} />
        </SideDrawer>
      )}
    </>
  );
};

export default NavBar;
